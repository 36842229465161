const Data = [
  {
    id: 1,
    name: "Types de Données",
    data: [
      { name: "Tableau", value: "faker.datatype.array" },
      { name: "Grand Entier", value: "faker.datatype.bigInt" },
      { name: "Booléen", value: "faker.datatype.boolean" },
      { name: "Date et Heure", value: "faker.datatype.datetime" },
      { name: "Flottant", value: "faker.datatype.float" },
      { name: "Hexadécimal", value: "faker.datatype.hexaDecimal" },
      { name: "JSON", value: "faker.datatype.json" },
      { name: "Nombre", value: "faker.datatype.number" },
      { name: "Chaîne", value: "faker.datatype.string" },
      { name: "UUID", value: "faker.datatype.uuid" },
    ],
  },
  {
    id: 2,
    name: "Adresse",
    data: [
      { name: "Numéro de Bâtiment", value: "faker.address.buildingNumber" },
      {
        name: "Direction Cardinale",
        value: "faker.address.cardinalDirection",
      },
      { name: "Ville", value: "faker.address.city" },
      { name: "Nom de la Ville", value: "faker.address.cityName" },
      { name: "Préfixe de Ville", value: "faker.address.cityPrefix" },
      { name: "Suffixe de Ville", value: "faker.address.citySuffix" },
      { name: "Pays", value: "faker.address.country" },
      { name: "Code du Pays", value: "faker.address.countryCode" },
      { name: "Comté", value: "faker.address.county" },
      { name: "Direction", value: "faker.address.direction" },
      { name: "Latitude", value: "faker.address.latitude" },
      { name: "Longitude", value: "faker.address.longitude" },
      {
        name: "Coordonnée GPS Proche",
        value: "faker.address.nearbyGPSCoordinate",
      },
      { name: "Direction Ordinale", value: "faker.address.ordinalDirection" },
      { name: "Adresse Secondaire", value: "faker.address.secondaryAddress" },
      { name: "État", value: "faker.address.state" },
      { name: "Abréviation de l'État", value: "faker.address.stateAbbr" },
      { name: "Adresse de Rue", value: "faker.address.streetAddress" },
      { name: "Nom de Rue", value: "faker.address.streetName" },
      { name: "Préfixe de Rue", value: "faker.address.streetPrefix" },
      { name: "Suffixe de Rue", value: "faker.address.streetSuffix" },
      { name: "Fuseau Horaire", value: "faker.address.timeZone" },
      { name: "Code Postal", value: "faker.address.zipCode" },
      { name: "Code Postal par État", value: "faker.address.zipCodeByState" },
    ],
  },
  {
    id: 3,
    name: "Date",
    data: [
      { name: "Entre", value: "faker.date.between" },
      { name: "Entre les Dates", value: "faker.date.betweens" },
      { name: "Anniversaire", value: "faker.date.birthday" },
      { name: "Futur", value: "faker.date.future" },
      { name: "Mois", value: "faker.date.month" },
      { name: "Passé", value: "faker.date.past" },
      { name: "Récemment", value: "faker.date.recent" },
      { name: "Bientôt", value: "faker.date.soon" },
      { name: "Jour de la Semaine", value: "faker.date.weekday" },
    ],
  },
  {
    id: 4,
    name: "Lorem Ipsum",
    data: [
      { name: "Lignes", value: "faker.lorem.lines" },
      { name: "Paragraphe", value: "faker.lorem.paragraph" },
      { name: "Paragraphes", value: "faker.lorem.paragraphs" },
      { name: "Phrase", value: "faker.lorem.sentence" },
      { name: "Phrases", value: "faker.lorem.sentences" },
      { name: "Slug", value: "faker.lorem.slug" },
      { name: "Texte", value: "faker.lorem.text" },
      { name: "Mot", value: "faker.lorem.word" },
      { name: "Mots", value: "faker.lorem.words" },
    ],
  },
  // {
  //   id: 5,
  //   name: "Mersenne",
  //   data: [
  //     { name: "Aléatoire", value: "faker.mersenne.rand" },
  //     { name: "Initialiser", value: "faker.mersenne.seed" },
  //     {
  //       name: "Tableau d'Initialisation",
  //       value: "faker.mersenne.seed_array",
  //     },
  //   ],
  // },
  {
    id: 6,
    name: "Animaux",
    data: [
      { name: "Chat", value: "faker.animal.cat" },
      { name: "Chien", value: "faker.animal.dog" },
      { name: "Cheval", value: "faker.animal.horse" },
      { name: "Serpent", value: "faker.animal.snake" },
      { name: "Lion", value: "faker.animal.lion" },
      { name: "Vache", value: "faker.animal.cow" },
      { name: "Grenouille", value: "faker.animal.frog" },
      { name: "Ours", value: "faker.animal.bear" },
      { name: "Singe", value: "faker.animal.monkey" },
      { name: "Oiseau", value: "faker.animal.bird" },
      { name: "Cétacé", value: "faker.animal.cetacean" },
      { name: "Poisson", value: "faker.animal.fish" },
      { name: "Crocodilien", value: "faker.animal.crocodilia" },
      { name: "Insecte", value: "faker.animal.insect" },
      { name: "Reptile", value: "faker.animal.reptile" },
    ],
  },
  {
    id: 7,
    name: "Finance",
    data: [
      { name: "Compte", value: "faker.finance.account" },
      { name: "Nom du Compte", value: "faker.finance.accountName" },
      { name: "Montant", value: "faker.finance.amount" },
      { name: "BIC", value: "faker.finance.bic" },
      { name: "Adresse Bitcoin", value: "faker.finance.bitcoinAddress" },
      {
        name: "CVV de Carte de Crédit",
        value: "faker.finance.creditCardCVV",
      },
      {
        name: "Émetteur de Carte de Crédit",
        value: "faker.finance.creditCardIssuer",
      },
      {
        name: "Numéro de Carte de Crédit",
        value: "faker.finance.creditCardNumber",
      },
      { name: "Code de Devise", value: "faker.finance.currencyCode" },
      { name: "Nom de Devise", value: "faker.finance.currencyName" },
      { name: "Symbole de Devise", value: "faker.finance.currencySymbol" },
      { name: "Adresse Ethereum", value: "faker.finance.ethereumAddress" },
      { name: "IBAN", value: "faker.finance.iban" },
      { name: "Type de Transaction", value: "faker.finance.transactionType" },
    ],
  },
  {
    id: 8,
    name: "Musique",
    data: [
      { name: "Genre", value: "faker.music.genre" },
      { name: "Nom de la Chanson", value: "faker.music.songName" },
    ],
  },
  {
    id: 9,
    name: "Nom",
    data: [
      { name: "Trouver un Nom", value: "faker.name.findName" },
      { name: "Prénom", value: "faker.name.firstName" },
      { name: "Nom Complet", value: "faker.name.fullName" },
      { name: "Genre", value: "faker.name.gender" },
      { name: "Domaine Professionnel", value: "faker.name.jobArea" },
      { name: "Descripteur de Poste", value: "faker.name.jobDescriptor" },
      { name: "Titre de Poste", value: "faker.name.jobTitle" },
      { name: "Type de Poste", value: "faker.name.jobType" },
      { name: "Nom de Famille", value: "faker.name.lastName" },
      { name: "Deuxième Prénom", value: "faker.name.middleName" },
      { name: "Préfixe", value: "faker.name.prefix" },
      { name: "Sexe", value: "faker.name.sex" },
      { name: "Type de Sexe", value: "faker.name.sexType" },
      { name: "Suffixe", value: "faker.name.suffix" },
    ],
  },
  {
    id: 10,
    name: "Git",
    data: [
      { name: "Branche", value: "faker.git.branch" },
      { name: "Entrée de Commit", value: "faker.git.commitEntry" },
      { name: "Message de Commit", value: "faker.git.commitMessage" },
      { name: "SHA de Commit", value: "faker.git.commitSha" },
      { name: "SHA Court", value: "faker.git.shortSha" },
    ],
  },
  {
    id: 11,
    name: "Téléphone",
    data: [
      { name: "IMEI", value: "faker.phone.imei" },
      { name: "Numéro", value: "faker.phone.number" },
      { name: "Formats de Téléphone", value: "faker.phone.phoneFormats" },
      { name: "Numéro de Téléphone", value: "faker.phone.phoneNumber" },
      {
        name: "Format de Numéro de Téléphone",
        value: "faker.phone.phoneNumberFormat",
      },
    ],
  },
  {
    id: 12,
    name: "Couleur",
    data: [
      { name: "CMYK", value: "faker.color.cmyk" },
      {
        name: "Couleur par Espace CSS",
        value: "faker.color.colorByCSSColorSpace",
      },
      {
        name: "Fonction Supportée par CSS",
        value: "faker.color.cssSupportedFunction",
      },
      {
        name: "Espace Supporté par CSS",
        value: "faker.color.cssSupportedSpace",
      },
      { name: "HSL", value: "faker.color.hsl" },
      { name: "Humain", value: "faker.color.human" },
      { name: "HWB", value: "faker.color.hwb" },
      { name: "LAB", value: "faker.color.lab" },
      { name: "LCH", value: "faker.color.lch" },
      { name: "RGB", value: "faker.color.rgb" },
      { name: "Espace", value: "faker.color.space" },
    ],
  },
  {
    id: 13,
    name: "Hacker",
    data: [
      { name: "Abréviation", value: "faker.hacker.abbreviation" },
      { name: "Adjectif", value: "faker.hacker.adjective" },
      { name: "Verbe ING", value: "faker.hacker.ingverb" },
      { name: "Nom", value: "faker.hacker.noun" },
      { name: "Phrase", value: "faker.hacker.phrase" },
      { name: "Verbe", value: "faker.hacker.verb" },
    ],
  },
  {
    id: 14,
    name: "Aléatoire",
    data: [
      { name: "Alpha", value: "faker.random.alpha" },
      { name: "Alphanumérique", value: "faker.random.alphaNumeric" },
      { name: "Locale", value: "faker.random.locale" },
      { name: "Numérique", value: "faker.random.numeric" },
      { name: "Mot", value: "faker.random.word" },
      { name: "Mots", value: "faker.random.words" },
    ],
  },
  {
    id: 15,
    name: "Commerce",
    data: [
      { name: "Couleur", value: "faker.commerce.color" },
      { name: "Département", value: "faker.commerce.department" },
      { name: "Prix", value: "faker.commerce.price" },
      { name: "Produit", value: "faker.commerce.product" },
      {
        name: "Adjectif du Produit",
        value: "faker.commerce.productAdjective",
      },
      {
        name: "Description du Produit",
        value: "faker.commerce.productDescription",
      },
      {
        name: "Matériau du Produit",
        value: "faker.commerce.productMaterial",
      },
      { name: "Nom du Produit", value: "faker.commerce.productName" },
    ],
  },
  {
    id: 16,
    name: "Science",
    data: [
      { name: "Élément Chimique", value: "faker.science.chemicalElement" },
      { name: "Unité", value: "faker.science.unit" },
    ],
  },
  {
    id: 17,
    name: "Entreprise",
    data: [
      { name: "BS", value: "faker.company.bs" },
      { name: "Adjectif BS", value: "faker.company.bsAdjective" },
      { name: "Buzz BS", value: "faker.company.bsBuzz" },
      { name: "Nom BS", value: "faker.company.bsNoun" },
      { name: "Phrase d'Accroche", value: "faker.company.catchPhrase" },
      {
        name: "Adjectif de Phrase d'Accroche",
        value: "faker.company.catchPhraseAdjective",
      },
      {
        name: "Descripteur de Phrase d'Accroche",
        value: "faker.company.catchPhraseDescriptor",
      },
      {
        name: "Nom de Phrase d'Accroche",
        value: "faker.company.catchPhraseNoun",
      },
      { name: "Nom de l'Entreprise", value: "faker.company.companyName" },
      {
        name: "Suffixe de l'Entreprise",
        value: "faker.company.companySuffix",
      },
      { name: "Nom", value: "faker.company.name" },
      { name: "Suffixes", value: "faker.company.suffixes" },
    ],
  },
  {
    id: 18,
    name: "Image",
    data: [
      { name: "Abstrait", value: "faker.image.abstract" },
      { name: "Animaux", value: "faker.image.animals" },
      { name: "Avatar", value: "faker.image.avatar" },
      { name: "Affaires", value: "faker.image.business" },
      { name: "Chats", value: "faker.image.cats" },
      { name: "Ville", value: "faker.image.city" },
      { name: "DataUri", value: "faker.image.dataUri" },
      { name: "Mode", value: "faker.image.fashion" },
      { name: "Nourriture", value: "faker.image.food" },
      { name: "Image", value: "faker.image.image" },
      { name: "ImageUrl", value: "faker.image.imageUrl" },
      { name: "Nature", value: "faker.image.nature" },
      { name: "Vie Nocturne", value: "faker.image.nightlife" },
      { name: "Personnes", value: "faker.image.people" },
      { name: "Sports", value: "faker.image.sports" },
      { name: "Techniques", value: "faker.image.technics" },
      { name: "Transport", value: "faker.image.transport" },
    ],
  },
  {
    id: 19,
    name: "Véhicule",
    data: [
      { name: "Vélo", value: "faker.vehicle.bicycle" },
      { name: "Couleur", value: "faker.vehicle.color" },
      { name: "Carburant", value: "faker.vehicle.fuel" },
      { name: "Fabricant", value: "faker.vehicle.manufacturer" },
      { name: "Modèle", value: "faker.vehicle.model" },
      { name: "Type", value: "faker.vehicle.type" },
      { name: "Véhicule", value: "faker.vehicle.vehicle" },
      { name: "VIN", value: "faker.vehicle.vin" },
      { name: "VRM", value: "faker.vehicle.vrm" },
    ],
  },
  {
    id: 20,
    name: "Internet",
    data: [
      { name: "Avatar", value: "faker.internet.avatar" },
      { name: "Couleur", value: "faker.internet.color" },
      { name: "Nom de Domaine", value: "faker.internet.domainName" },
      { name: "Suffixe de Domaine", value: "faker.internet.domainSuffix" },
      { name: "Mot de Domaine", value: "faker.internet.domainWord" },
      { name: "Email", value: "faker.internet.email" },
      { name: "Emoji", value: "faker.internet.emoji" },
      { name: "Email Exemple", value: "faker.internet.exampleEmail" },
      { name: "Méthode HTTP", value: "faker.internet.httpMethod" },
      { name: "Code de Statut HTTP", value: "faker.internet.httpStatusCode" },
      { name: "IP", value: "faker.internet.ip" },
      { name: "IPv4", value: "faker.internet.ipv4" },
      { name: "IPv6", value: "faker.internet.ipv6" },
      { name: "MAC", value: "faker.internet.mac" },
      { name: "Mot de Passe", value: "faker.internet.password" },
      { name: "Port", value: "faker.internet.port" },
      { name: "Protocole", value: "faker.internet.protocol" },
      { name: "URL", value: "faker.internet.url" },
      { name: "Agent Utilisateur", value: "faker.internet.userAgent" },
      { name: "Nom d'Utilisateur", value: "faker.internet.userName" },
    ],
  },
  {
    id: 21,
    name: "Mot",
    data: [
      { name: "Adjectif", value: "faker.word.adjective" },
      { name: "Adverbe", value: "faker.word.adverb" },
      { name: "Conjonction", value: "faker.word.conjunction" },
      { name: "Interjection", value: "faker.word.interjection" },
      { name: "Nom", value: "faker.word.noun" },
      { name: "Préposition", value: "faker.word.preposition" },
      { name: "Verbe", value: "faker.word.verb" },
    ],
  },
];
export default Data;
