import { faker } from "@faker-js/faker";

// Tableau de correspondance des chaînes aux fonctions faker
const fakerFunctionMap = {
  "faker.datatype.array": faker.datatype.array,
  "faker.datatype.bigInt": faker.datatype.bigInt,
  "faker.datatype.boolean": faker.datatype.boolean,
  "faker.datatype.datetime": faker.datatype.datetime,
  "faker.datatype.float": faker.datatype.float,
  "faker.datatype.hexaDecimal": faker.datatype.hexaDecimal,
  "faker.datatype.json": faker.datatype.json,
  "faker.datatype.number": faker.datatype.number,
  "faker.datatype.string": faker.datatype.string,
  "faker.datatype.uuid": faker.datatype.uuid,
  "faker.address.buildingNumber": faker.address.buildingNumber,
  "faker.address.cardinalDirection": faker.address.cardinalDirection,
  "faker.address.city": faker.address.city,
  "faker.address.cityName": faker.address.cityName,
  "faker.address.cityPrefix": faker.address.cityPrefix,
  "faker.address.citySuffix": faker.address.citySuffix,
  "faker.address.country": faker.address.country,
  "faker.address.countryCode": faker.address.countryCode,
  "faker.address.county": faker.address.county,
  "faker.address.direction": faker.address.direction,
  "faker.address.latitude": faker.address.latitude,
  "faker.address.longitude": faker.address.longitude,
  "faker.address.nearbyGPSCoordinate": faker.address.nearbyGPSCoordinate,
  "faker.address.ordinalDirection": faker.address.ordinalDirection,
  "faker.address.secondaryAddress": faker.address.secondaryAddress,
  "faker.address.state": faker.address.state,
  "faker.address.stateAbbr": faker.address.stateAbbr,
  "faker.address.streetAddress": faker.address.streetAddress,
  "faker.address.streetName": faker.address.streetName,
  "faker.address.streetPrefix": faker.address.streetPrefix,
  "faker.address.streetSuffix": faker.address.streetSuffix,
  "faker.address.timeZone": faker.address.timeZone,
  "faker.address.zipCode": faker.address.zipCode,
  "faker.address.zipCodeByState": faker.address.zipCodeByState,
  "faker.date.between": faker.date.between,
  "faker.date.betweens": faker.date.betweens,
  "faker.date.birthday": faker.date.birthday,
  "faker.date.future": faker.date.future,
  "faker.date.month": faker.date.month,
  "faker.date.past": faker.date.past,
  "faker.date.recent": faker.date.recent,
  "faker.date.soon": faker.date.soon,
  "faker.date.weekday": faker.date.weekday,
  "faker.lorem.lines": faker.lorem.lines,
  "faker.lorem.paragraph": faker.lorem.paragraph,
  "faker.lorem.paragraphs": faker.lorem.paragraphs,
  "faker.lorem.sentence": faker.lorem.sentence,
  "faker.lorem.sentences": faker.lorem.sentences,
  "faker.lorem.slug": faker.lorem.slug,
  "faker.lorem.text": faker.lorem.text,
  "faker.lorem.word": faker.lorem.word,
  "faker.lorem.words": faker.lorem.words,
  // "faker.mersenne.rand": faker.mersenne.rand,
  // "faker.mersenne.seed": faker.mersenne.seed,
  // "faker.mersenne.seed_array": faker.mersenne.seed_array,
  "faker.animal.cat": faker.animal.cat,
  "faker.animal.dog": faker.animal.dog,
  "faker.animal.horse": faker.animal.horse,
  "faker.animal.snake": faker.animal.snake,
  "faker.animal.lion": faker.animal.lion,
  "faker.animal.cow": faker.animal.cow,
  "faker.animal.frog": faker.animal.frog,
  "faker.animal.bear": faker.animal.bear,
  "faker.animal.monkey": faker.animal.monkey,
  "faker.animal.bird": faker.animal.bird,
  "faker.animal.cetacean": faker.animal.cetacean,
  "faker.animal.fish": faker.animal.fish,
  "faker.animal.crocodilia": faker.animal.crocodilia,
  "faker.animal.insect": faker.animal.insect,
  "faker.animal.reptile": faker.animal.reptile,
  "faker.finance.account": faker.finance.account,
  "faker.finance.accountName": faker.finance.accountName,
  "faker.finance.amount": faker.finance.amount,
  "faker.finance.bic": faker.finance.bic,
  "faker.finance.bitcoinAddress": faker.finance.bitcoinAddress,
  "faker.finance.creditCardCVV": faker.finance.creditCardCVV,
  "faker.finance.creditCardIssuer": faker.finance.creditCardIssuer,
  "faker.finance.creditCardNumber": faker.finance.creditCardNumber,
  "faker.finance.currencyCode": faker.finance.currencyCode,
  "faker.finance.currencyName": faker.finance.currencyName,
  "faker.finance.currencySymbol": faker.finance.currencySymbol,
  "faker.finance.ethereumAddress": faker.finance.ethereumAddress,
  "faker.finance.iban": faker.finance.iban,
  "faker.finance.transactionType": faker.finance.transactionType,
  "faker.music.genre": faker.music.genre,
  "faker.music.songName": faker.music.songName,
  "faker.name.findName": faker.name.findName,
  "faker.name.firstName": faker.name.firstName,
  "faker.name.fullName": faker.name.fullName,
  "faker.name.gender": faker.name.gender,
  "faker.name.jobArea": faker.name.jobArea,
  "faker.name.jobDescriptor": faker.name.jobDescriptor,
  "faker.name.jobTitle": faker.name.jobTitle,
  "faker.name.jobType": faker.name.jobType,
  "faker.name.lastName": faker.name.lastName,
  "faker.name.middleName": faker.name.middleName,
  "faker.name.prefix": faker.name.prefix,
  "faker.name.sex": faker.name.sex,
  "faker.name.sexType": faker.name.sexType,
  "faker.name.suffix": faker.name.suffix,
  "faker.git.branch": faker.git.branch,
  "faker.git.commitEntry": faker.git.commitEntry,
  "faker.git.commitMessage": faker.git.commitMessage,
  "faker.git.commitSha": faker.git.commitSha,
  "faker.git.shortSha": faker.git.shortSha,
  "faker.phone.imei": faker.phone.imei,
  "faker.phone.number": faker.phone.number,
  "faker.phone.phoneFormats": faker.phone.phoneFormats,
  "faker.phone.phoneNumber": faker.phone.phoneNumber,
  "faker.phone.phoneNumberFormat": faker.phone.phoneNumberFormat,
  "faker.color.cmyk": faker.color.cmyk,
  "faker.color.colorByCSSColorSpace": faker.color.colorByCSSColorSpace,
  "faker.color.cssSupportedFunction": faker.color.cssSupportedFunction,
  "faker.color.cssSupportedSpace": faker.color.cssSupportedSpace,
  "faker.color.hsl": faker.color.hsl,
  "faker.color.human": faker.color.human,
  "faker.color.hwb": faker.color.hwb,
  "faker.color.lab": faker.color.lab,
  "faker.color.lch": faker.color.lch,
  "faker.color.rgb": faker.color.rgb,
  "faker.color.space": faker.color.space,
  "faker.hacker.abbreviation": faker.hacker.abbreviation,
  "faker.hacker.adjective": faker.hacker.adjective,
  "faker.hacker.ingverb": faker.hacker.ingverb,
  "faker.hacker.noun": faker.hacker.noun,
  "faker.hacker.phrase": faker.hacker.phrase,
  "faker.hacker.verb": faker.hacker.verb,
  "faker.random.alpha": faker.random.alpha,
  "faker.random.alphaNumeric": faker.random.alphaNumeric,
  "faker.random.locale": faker.random.locale,
  "faker.random.numeric": faker.random.numeric,
  "faker.random.word": faker.random.word,
  "faker.random.words": faker.random.words,
  "faker.commerce.color": faker.commerce.color,
  "faker.commerce.department": faker.commerce.department,
  "faker.commerce.price": faker.commerce.price,
  "faker.commerce.product": faker.commerce.product,
  "faker.commerce.productAdjective": faker.commerce.productAdjective,
  "faker.commerce.productDescription": faker.commerce.productDescription,
  "faker.commerce.productMaterial": faker.commerce.productMaterial,
  "faker.commerce.productName": faker.commerce.productName,
  "faker.science.chemicalElement": faker.science.chemicalElement,
  "faker.science.unit": faker.science.unit,
  "faker.company.bs": faker.company.bs,
  "faker.company.bsAdjective": faker.company.bsAdjective,
  "faker.company.bsBuzz": faker.company.bsBuzz,
  "faker.company.bsNoun": faker.company.bsNoun,
  "faker.company.catchPhrase": faker.company.catchPhrase,
  "faker.company.catchPhraseAdjective": faker.company.catchPhraseAdjective,
  "faker.company.catchPhraseDescriptor": faker.company.catchPhraseDescriptor,
  "faker.company.catchPhraseNoun": faker.company.catchPhraseNoun,
  "faker.company.companyName": faker.company.companyName,
  "faker.company.companySuffix": faker.company.companySuffix,
  "faker.company.name": faker.company.name,
  "faker.company.suffixes": faker.company.suffixes,
  "faker.image.abstract": faker.image.abstract,
  "faker.image.animals": faker.image.animals,
  "faker.image.avatar": faker.image.avatar,
  "faker.image.business": faker.image.business,
  "faker.image.cats": faker.image.cats,
  "faker.image.city": faker.image.city,
  "faker.image.dataUri": faker.image.dataUri,
  "faker.image.fashion": faker.image.fashion,
  "faker.image.food": faker.image.food,
  "faker.image.image": faker.image.image,
  "faker.image.imageUrl": faker.image.imageUrl,
  "faker.image.nature": faker.image.nature,
  "faker.image.nightlife": faker.image.nightlife,
  "faker.image.people": faker.image.people,
  "faker.image.sports": faker.image.sports,
  "faker.image.technics": faker.image.technics,
  "faker.image.transport": faker.image.transport,
  "faker.vehicle.bicycle": faker.vehicle.bicycle,
  "faker.vehicle.color": faker.vehicle.color,
  "faker.vehicle.fuel": faker.vehicle.fuel,
  "faker.vehicle.manufacturer": faker.vehicle.manufacturer,
  "faker.vehicle.model": faker.vehicle.model,
  "faker.vehicle.type": faker.vehicle.type,
  "faker.vehicle.vehicle": faker.vehicle.vehicle,
  "faker.vehicle.vin": faker.vehicle.vin,
  "faker.vehicle.vrm": faker.vehicle.vrm,
  "faker.internet.avatar": faker.internet.avatar,
  "faker.internet.color": faker.internet.color,
  "faker.internet.domainName": faker.internet.domainName,
  "faker.internet.domainSuffix": faker.internet.domainSuffix,
  "faker.internet.domainWord": faker.internet.domainWord,
  "faker.internet.email": faker.internet.email,
  "faker.internet.emoji": faker.internet.emoji,
  "faker.internet.exampleEmail": faker.internet.exampleEmail,
  "faker.internet.httpMethod": faker.internet.httpMethod,
  "faker.internet.httpStatusCode": faker.internet.httpStatusCode,
  "faker.internet.ip": faker.internet.ip,
  "faker.internet.ipv4": faker.internet.ipv4,
  "faker.internet.ipv6": faker.internet.ipv6,
  "faker.internet.mac": faker.internet.mac,
  "faker.internet.password": faker.internet.password,
  "faker.internet.port": faker.internet.port,
  "faker.internet.protocol": faker.internet.protocol,
  "faker.internet.url": faker.internet.url,
  "faker.internet.userAgent": faker.internet.userAgent,
  "faker.internet.userName": faker.internet.userName,
  "faker.word.adjective": faker.word.adjective,
  "faker.word.adverb": faker.word.adverb,
  "faker.word.conjunction": faker.word.conjunction,
  "faker.word.interjection": faker.word.interjection,
  "faker.word.noun": faker.word.noun,
  "faker.word.preposition": faker.word.preposition,
  "faker.word.verb": faker.word.verb,
};

// Fonction pour récupérer une fonction faker depuis une chaîne
function getFakerFunctionByString(functionString) {
  if (fakerFunctionMap[functionString]) {
    return fakerFunctionMap[functionString]; // Retourne la fonction correspondante
  } else {
    console.error("Fonction non reconnue:", functionString);
    return null;
  }
}

export { getFakerFunctionByString };
