import { faker } from "@faker-js/faker";

const addDataToNestedStructure = (currentData, data) => {
  if (!Array.isArray(currentData)) {
    return currentData;
  }
  const newData = {
    id: faker.datatype.uuid(),
    type: "default",
    typeId: 1,
    name: "new Data " + Math.floor(Math.random() * 100),
    parentName: data.name,
    value: "faker.company.name",
  };

  return currentData.map((item) => {
    if (
      item.id === data.id &&
      (item.type === "array" || item.type === "object")
    ) {
      // Found the target parent, insert new data
      return { ...item, value: [...item.value, newData] };
    } else if (item.value && Array.isArray(item.value)) {
      // Recursively update children
      return {
        ...item,
        value: addDataToNestedStructure(newData, item.value, data),
      };
    }
    return item;
  });
};

export { addDataToNestedStructure };
